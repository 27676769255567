import React from 'react'
import EnterpriseKelpLanding, {
  type EnterpriseKelpLandingProps,
} from 'features/enterprise/kelp/components/EnterpriseKelpLanding'
import * as enterprise from 'libs/enterprise'

type Props = EnterpriseKelpLandingProps

export default function EnterpriseKelpMidAtlantic(props: Props) {
  return (
    <EnterpriseKelpLanding
      {...props}
      partner={enterprise.KELP_MID_ATLANTIC_KEY}
      timeZone="ET"
      className="EnterpriseKelpMidAtlantic"
    />
  )
}
